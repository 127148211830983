import axios from "axios";

export interface Event {
  id: string;
  title: string;
  date: string;
  description: string;
  image: string;
  location?: string;
  time?: string;
  eventLink?: string;
}

// Sheet IDs voor verschillende omgevingen
const SHEET_CONFIG = {
  development: {
    id: "1qoVnl4udc5F3LpMnqeftOjMBM9VevonDMxrSRO2N6as",
    name: "Sheet1",
  },
  production: {
    id: "11mxHf2KDkRGLmxuMe2HE9NnCKuJ73uPsgjwWX1Ec6nU",
    name: "Sheet1",
  },
};

// Bepaal de huidige omgeving - use import.meta.env for Vite compatibility
const ENV = import.meta.env.MODE || "development";
console.log("Current environment for eventService:", ENV);

// Selecteer de juiste sheet configuratie
const SHEET_ID = SHEET_CONFIG[ENV as keyof typeof SHEET_CONFIG].id;
const SHEET_NAME = SHEET_CONFIG[ENV as keyof typeof SHEET_CONFIG].name;

function formatDate(dateString: string): string {
  try {
    if (dateString.startsWith("Date(")) {
      const parts = dateString
        .replace("Date(", "")
        .replace(")", "")
        .split(",")
        .map(Number);

      const date = new Date(parts[0], parts[1], parts[2]);

      return date.toLocaleDateString("nl-NL", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    }
    return dateString;
  } catch (error) {
    console.error("Error formatting date:", error);
    return dateString;
  }
}

function formatTime(timeString: string): string {
  try {
    if (timeString.startsWith("Date(")) {
      const parts = timeString
        .replace("Date(", "")
        .replace(")", "")
        .split(",")
        .map(Number);

      const date = new Date(1970, 0, 1, parts[3], parts[4], parts[5]);

      return date.toLocaleTimeString("nl-NL", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
    }
    return timeString;
  } catch (error) {
    console.error("Error formatting time:", error);
    return timeString;
  }
}

function formatUrl(url: string | undefined): string | undefined {
  if (!url) return undefined;

  try {
    url = url.trim();

    if (url.startsWith("http://") || url.startsWith("https://")) {
      return url;
    }

    if (url.startsWith("www.")) {
      return `https://${url}`;
    }

    if (!url.includes(".")) {
      return undefined;
    }

    return `https://www.${url}`;
  } catch (error) {
    console.error("Error formatting URL:", error);
    return undefined;
  }
}

function getImageUrl(url: string): string {
  try {
    if (url.startsWith("https://images.unsplash.com/")) {
      return url;
    }
    if (url.includes("unsplash.com/photos/")) {
      return "https://source.unsplash.com/random/800x600/?aikido,martial-arts,japan";
    }
    if (!url) {
      return "https://source.unsplash.com/random/800x600/?aikido,martial-arts,japan";
    }
    return url;
  } catch (error) {
    console.error("Error processing image URL:", error);
    return "https://source.unsplash.com/random/800x600/?aikido,martial-arts,japan";
  }
}

export async function fetchEvents(): Promise<Event[]> {
  try {
    console.log(`Using ${ENV} sheet configuration:`, { SHEET_ID, SHEET_NAME });

    const response = await axios.get(
      `https://docs.google.com/spreadsheets/d/${SHEET_ID}/gviz/tq?tqx=out:json&sheet=${SHEET_NAME}`
    );

    const data = JSON.parse(response.data.substring(47).slice(0, -2));
    console.log("Raw Google Sheets data:", data.table.rows);

    if (!data.table.rows) return [];

    // Define a type for Google Sheets row cell
    type GoogleSheetCell = { v: string | number | null | Date; f?: string };
    // Define a type for Google Sheets row
    type GoogleSheetRow = { c: GoogleSheetCell[] };

    return data.table.rows
      .filter((row: GoogleSheetRow) => row.c[1]?.v)
      .map((row: GoogleSheetRow, index: number) => {
        const imageUrl = String(row.c[4]?.v || "");
        const dateStr = String(row.c[2]?.v || "");
        const timeStr = String(row.c[6]?.v || "");
        const eventLinkStr =
          row.c[7]?.v === null ? undefined : String(row.c[7]?.v || "");
        console.log("Processing row:", row);
        return {
          id: index.toString(),
          title: String(row.c[1]?.v || ""),
          date: formatDate(dateStr),
          description: String(row.c[3]?.v || ""),
          image: getImageUrl(imageUrl),
          location:
            row.c[5]?.v === null ? undefined : String(row.c[5]?.v || ""),
          time: timeStr ? formatTime(timeStr) : undefined,
          eventLink: formatUrl(eventLinkStr),
        };
      });
  } catch (error) {
    console.error("Error fetching events:", error);
    throw new Error(
      "Er is een probleem opgetreden bij het ophalen van de evenementen. Probeer het later opnieuw."
    );
  }
}
