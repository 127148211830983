import React, { useState } from 'react';
import { Calendar, Clock, MapPin, Link as LinkIcon } from 'lucide-react';

interface EventCardProps {
  title: string;
  date: string;
  description: string;
  imageUrl: string;
  location?: string;
  time?: string;
  eventLink?: string;
}

export default function EventCard({ 
  title, 
  date, 
  description, 
  imageUrl,
  location,
  time,
  eventLink,
}: EventCardProps) {
  const [imgError, setImgError] = useState(false);

  const getImageUrl = (url: string) => {
    // Log de originele URL voor debugging
    console.log('Original image URL:', url);
    
    // Als het een volledige URL is (begint met http of https), gebruik deze direct
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    }
    
    // Als het een URL is die begint met www, voeg https:// toe
    if (url.startsWith('www.')) {
      return `https://${url}`;
    }
    
    // Anders, bouw een relatieve URL op naar de images folder
    return `/images/${url}`;
  };

  // Gebruik een standaard fallback afbeelding als er geen URL is of als deze mislukt
  const defaultImage = 'https://source.unsplash.com/random/800x600/?aikido,martial-arts,japan';

  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl">
      <div className="flex flex-col">
        {/* Header met titel en datum/tijd */}
        <div className="p-4">
          <h3 className="text-xl font-semibold">{title}</h3>
          <div className="flex items-center text-gray-600 mt-1">
            <Calendar className="w-4 h-4 mr-2" />
            <span>{date}</span>
            {time && (
              <>
                <Clock className="w-4 h-4 ml-4 mr-2" />
                <span>{time}</span>
              </>
            )}
          </div>
        </div>

        {/* Afbeelding */}
        <div className="relative h-72">
          {imageUrl && !imgError ? (
            <img 
              src={getImageUrl(imageUrl)} 
              alt={title} 
              className="absolute inset-0 w-full h-full object-cover"
              onError={(e) => {
                console.error('Image failed to load:', imageUrl);
                setImgError(true);
                const target = e.target as HTMLImageElement;
                target.src = defaultImage;
              }}
            />
          ) : (
            <img 
              src={defaultImage}
              alt={title} 
              className="absolute inset-0 w-full h-full object-cover"
            />
          )}
        </div>

        {/* Content */}
        <div className="p-6 pt-2">
          {location && (
            <div className="flex items-center text-gray-600 mb-4">
              <MapPin className="w-4 h-4 mr-2" />
              <span>{location}</span>
            </div>
          )}
          <p className="text-gray-600 mb-4">{description}</p>
          {eventLink && (
            <a 
              href={eventLink}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center text-primary-600 hover:text-primary-700"
            >
              <LinkIcon className="w-4 h-4 mr-2" />
              Meer informatie
            </a>
          )}
        </div>
      </div>
    </div>
  );
}