import { useEffect, useState } from 'react';
import { PastEvent, fetchPastEvents } from '../services/pastEventService';

export default function PastEvents() {
  const [pastEvents, setPastEvents] = useState<PastEvent[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  
  // Functie voor het correct weergeven van afbeeldingspaden
  const getImageUrl = (url: string): string => {
    if (!url) return '/images/aikido-social.jpg';
    
    // Als het een volledige URL is, gebruik deze direct
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    }
    
    // Als het pad nog niet begint met /images/, voeg dit toe
    if (!url.startsWith('/images/') && !url.startsWith('/')) {
      return `/images/${url}`;
    }
    
    return url;
  };

  useEffect(() => {
    const loadPastEvents = async () => {
      try {
        console.log('PastEvents: Starting to fetch past events');
        const events = await fetchPastEvents();
        console.log('PastEvents: Received events:', events);
        setPastEvents(events);
        setError(null);
      } catch (err: unknown) {
        const errorObj = err as Error;
        const errorMessage = errorObj.message || 'Er is een probleem opgetreden bij het laden van de evenementen.';
        setError(errorMessage);
        console.error('Error loading past events:', errorObj);
      } finally {
        setLoading(false);
      }
    };

    loadPastEvents();
  }, []);

  if (loading) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 mt-10">
        <p>Laden...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 mt-10">
        <p className="text-red-600">{error}</p>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 mt-10">
      <h1 className="text-4xl font-bold mb-8">Eerdere Evenementen</h1>
      
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-2">
        {pastEvents.map((event) => (
          <div key={event.id} className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="h-48 w-full overflow-hidden">
              <img
                src={getImageUrl(event.image)}
                alt={event.title}
                className="w-full h-full object-cover"
                onError={(e) => {
                  console.error('Afbeelding kon niet worden geladen:', event.image);
                  const target = e.target as HTMLImageElement;
                  target.src = '/images/aikido-social.jpg';
                }}
              />
            </div>
            <div className="p-6">
              <h3 className="text-xl font-semibold mb-2">{event.title}</h3>
              <p className="text-gray-600 mb-2">{event.date}</p>
              <p className="text-gray-600 mb-4">{event.location}</p>
              <p className="text-gray-700">{event.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
