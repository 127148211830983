import axios from "axios";

export interface PastEvent {
  id: string;
  title: string;
  date: string;
  description: string;
  location: string;
  image: string;
}

// Sheet IDs voor verschillende omgevingen
const SHEET_CONFIG = {
  development: {
    id: "1Ol8UqcOWUPetCpM5TKye0h8J0EPWvsDBimwZYBujvOA",
    name: "Form Responses 1",
  },
  production: {
    id: "1ecE0Al1xjSuPtNrqrX3N1xXlCJgGiYDKq455zwfMDFQ",
    name: "Form Responses 1",
  },
};

// Bepaal de huidige omgeving - use import.meta.env for Vite compatibility
const ENV = import.meta.env.MODE || "development";
console.log("Current environment for pastEventService:", ENV);

// Selecteer de juiste sheet configuratie
const SHEET_ID = SHEET_CONFIG[ENV as keyof typeof SHEET_CONFIG].id;
const SHEET_NAME = SHEET_CONFIG[ENV as keyof typeof SHEET_CONFIG].name;

function formatDate(dateString: string): string {
  try {
    if (dateString.startsWith("Date(")) {
      const parts = dateString
        .replace("Date(", "")
        .replace(")", "")
        .split(",")
        .map(Number);

      const date = new Date(parts[0], parts[1], parts[2]);

      return date.toLocaleDateString("nl-NL", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    }

    // Als het geen Date() string is, probeer het als normale datum te parsen
    const date = new Date(dateString);
    if (!isNaN(date.getTime())) {
      return date.toLocaleDateString("nl-NL", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    }

    return dateString;
  } catch (error) {
    console.error("Error formatting date:", error);
    return dateString;
  }
}

function getImageUrl(url: string): string {
  try {
    if (url.startsWith("https://images.unsplash.com/")) {
      return url;
    }
    if (url.includes("unsplash.com/photos/")) {
      return "https://source.unsplash.com/random/800x600/?aikido,martial-arts,japan";
    }
    if (!url) {
      return "https://source.unsplash.com/random/800x600/?aikido,martial-arts,japan";
    }
    return url;
  } catch (error) {
    console.error("Error processing image URL:", error);
    return "https://source.unsplash.com/random/800x600/?aikido,martial-arts,japan";
  }
}

export async function fetchPastEvents(): Promise<PastEvent[]> {
  try {
    console.log(`Using ${ENV} sheet configuration for past events:`, {
      SHEET_ID,
      SHEET_NAME,
    });
    console.log(
      "Full Google Sheets URL:",
      `https://docs.google.com/spreadsheets/d/${SHEET_ID}/gviz/tq?tqx=out:json&sheet=${SHEET_NAME}`
    );

    const response = await axios.get(
      `https://docs.google.com/spreadsheets/d/${SHEET_ID}/gviz/tq?tqx=out:json&sheet=${SHEET_NAME}`
    );

    console.log("Raw Google Sheets response data type:", typeof response.data);
    console.log(
      "Raw Google Sheets response data length:",
      response.data.length
    );
    console.log(
      "Raw Google Sheets response data substring:",
      response.data.substring(0, 100)
    );

    // Add special handling to safely parse the Google Sheets response
    let data;
    try {
      data = JSON.parse(response.data.substring(47).slice(0, -2));
      console.log("Parsed Google Sheets data:", data);
    } catch (parseError) {
      console.error("Error parsing Google Sheets data:", parseError);
      console.warn(
        "Raw response substring for debugging:",
        response.data.substring(0, 200)
      );
      throw new Error(
        "Failed to parse Google Sheets data. The response format might have changed."
      );
    }

    console.log("Google Sheets data table structure:", data);

    if (!data.table || !data.table.rows) {
      console.warn("No rows found in Google Sheets response");
      return [];
    }

    console.log("Raw Google Sheets data for past events:", data.table.rows);

    // Define a type for Google Sheets row cell
    type GoogleSheetCell = { v: string | number | null | Date; f?: string };
    // Define a type for Google Sheets row
    type GoogleSheetRow = { c: GoogleSheetCell[] };

    return data.table.rows
      .filter((row: GoogleSheetRow) => row.c[1]?.v) // Filter op titel
      .map((row: GoogleSheetRow, index: number) => {
        const dateStr = row.c[2]?.v || "";
        console.log("Processing past event row:", row);
        console.log("Date string:", dateStr);

        return {
          id: index.toString(),
          title: String(row.c[1]?.v || ""),
          date: formatDate(String(dateStr)),
          description: String(row.c[3]?.v || ""),
          location: String(row.c[4]?.v || ""),
          image: getImageUrl(String(row.c[5]?.v || "")),
        };
      });
  } catch (error) {
    console.error("Error fetching past events:", error);
    if (error instanceof Error) {
      console.error("Error message:", error.message);
      console.error("Error stack:", error.stack);
    }

    // Check if it's an Axios error
    if (axios.isAxiosError(error)) {
      console.error("Axios error details:", {
        status: error.response?.status,
        statusText: error.response?.statusText,
        data: error.response?.data,
      });

      // Network or CORS issue
      if (!error.response) {
        throw new Error(
          "Netwerkfout bij het verbinden met Google Sheets. Mogelijk een CORS probleem."
        );
      }

      // Authentication or permission issue
      if (error.response.status === 403) {
        throw new Error(
          "Geen toegang tot het Google Sheets document. Controleer de machtigingen."
        );
      }
    }

    throw new Error(
      "Er is een probleem opgetreden bij het ophalen van de eerdere evenementen. Probeer het later opnieuw."
    );
  }
}
